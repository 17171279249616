import {
  createUpgradePlanStageStepComment,
  createUpgradeTemplateStageStepComment,
  useListUpgradePlanStageStepComments,
  useListUpgradeTemplateStageStepComments,
} from 'api/frontend';
import {
  UpgradePlan,
  UpgradeStage,
  UpgradeStageStep,
  UpgradeTemplate,
} from 'api/models';
import useUserAccountState from 'hooks/useUserAccountState';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/browser';
import {
  Accordion,
  Avatar,
  Button,
  ChatList,
  Loader,
  SeeAllButton,
  TextArea,
} from 'subframe/index';
import { useState } from 'react';
import { somethingWentWrong } from 'constants/toasts';
import { getHumanReadableDateAndTime } from 'utils/formatTime';
import { AtomicTooltip } from 'components/design-system';

export default function StepDrawerCommentsForTeam(props: {
  showExampleData: boolean;
  upgrade: UpgradePlan | UpgradeTemplate;
  stage: UpgradeStage;
  step: UpgradeStageStep;
  isTemplate?: boolean;
}) {
  const { account, user } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState('');
  const [seeAllMessages, setSeeAllMessages] = useState(false);
  const { data: commentsData, mutate: commentsMutate } = props.isTemplate
    ? useListUpgradeTemplateStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
        },
      )
    : useListUpgradePlanStageStepComments(
        props.upgrade.id,
        props.stage.stage_number.toFixed(),
        props.step.step_number.toFixed(),
        {},
        {
          request: {
            headers: { Authorization: `Bearer ${account.token}` },
          },
          swr: {
            enabled: !props.showExampleData,
          },
        },
      );
  const postComment = async (comment: string) => {
    try {
      setIsSending(true);

      if (props.isTemplate) {
        await createUpgradeTemplateStageStepComment(
          props.upgrade.id,
          props.stage.stage_number.toFixed(),
          props.step.step_number.toFixed(),
          { comment },
          {
            headers: {
              Authorization: `Bearer ${account.token}`,
            },
          },
        );
      } else {
        await createUpgradePlanStageStepComment(
          props.upgrade.id,
          props.stage.stage_number.toFixed(),
          props.step.step_number.toFixed(),
          { comment },
          {
            headers: {
              Authorization: `Bearer ${account.token}`,
            },
          },
        );
      }

      await commentsMutate();
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'posting comment'),
        { variant: 'error' },
      );
    } finally {
      setIsSending(false);
    }
  };

  const disableActionsString =
    props.upgrade.status === 'pending' ||
    props.upgrade.status === 'cancelled' ||
    props.upgrade.status === 'completed'
      ? `The Upgrade ${
          props.isTemplate ? 'Template' : 'Plan'
        } is read-only and can no longer be commented on.`
      : undefined;

  const commentsList =
    commentsData?.data.filter((item) => !item.is_for_chkk) || [];

  if (props.showExampleData) {
    return <></>;
  }

  return (
    <div className="flex w-full flex-col items-start gap-4 rounded-md border border-solid border-neutral-border bg-default-background shadow-sm">
      <div className="flex w-full flex-col items-start border-b border-solid border-neutral-border">
        <Accordion
          trigger={
            <div className="flex w-full items-center gap-2 border-t border-solid border-neutral-border px-6 py-5">
              <span className="grow shrink-0 basis-0 text-subheader font-subheader text-default-font">
                Leave Comment for your team
              </span>
              <Accordion.Chevron />
            </div>
          }
          defaultOpen={true}
        >
          <div className="flex w-full grow shrink-0 basis-0 flex-col items-start gap-4 px-6 pb-6">
            <div className="flex w-full flex-col items-start gap-4">
              {commentsData ? (
                <>
                  <ChatList className="h-auto w-full flex-none">
                    {commentsList
                      .slice(0, seeAllMessages ? commentsList.length : 2)
                      .map((item, idx) => {
                        return (
                          <ChatList.ChatListItem
                            key={idx}
                            name={item.user.name}
                            avatar={<Avatar image={item.user.picture} />}
                            time={getHumanReadableDateAndTime(item.time)} // same date time format as in Cluster Properties
                            message={item.comment}
                          />
                        );
                      })}
                  </ChatList>
                  {commentsList.length > 2 && (
                    <>
                      {seeAllMessages ? (
                        <SeeAllButton
                          text="See less..."
                          onClick={() => setSeeAllMessages(false)}
                        />
                      ) : (
                        <SeeAllButton
                          text={`See ${
                            commentsList.length - 2
                          } more comments...`}
                          onClick={() => setSeeAllMessages(true)}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <Loader />
              )}
              <div className="flex w-full flex-col items-start gap-4">
                <div className="flex w-full flex-col items-end gap-2 py-4">
                  <div className="flex w-full items-start gap-2">
                    <Avatar image={user.profilePicture} />
                    <TextArea
                      className="h-auto grow shrink-0 basis-0"
                      label=""
                      helpText=""
                    >
                      <TextArea.Input
                        placeholder="Post comment"
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            postComment(message);
                            setMessage('');
                          }
                        }}
                        disabled={!!disableActionsString || isSending}
                      />
                    </TextArea>
                  </div>
                  {disableActionsString ? (
                    <AtomicTooltip
                      tooltipContent={`Action not allowed. ${disableActionsString}`}
                    >
                      <Button variant="neutral-secondary" disabled={true}>
                        Comment
                      </Button>
                    </AtomicTooltip>
                  ) : (
                    <Button
                      variant="neutral-secondary"
                      disabled={!!disableActionsString || isSending}
                      loading={isSending}
                      onClick={() => {
                        message && postComment(message);
                        setMessage('');
                      }}
                    >
                      Comment
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    </div>
  );
}
